import { LitElement, html } from 'lit';
import Papa from "papaparse";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import './components/bank-select.js';
import './components/file-input.js';
import './components/info-box.js';

import { getDocument, extractSerialNumbers, generateToken, findDateTime, splitDescription } from "./utils.js";

// Make sure dayJS can parse different kind of date formats
dayjs.extend(customParseFormat)

export function convertDate(text) {
  const formats = ['DD/MM', 'DD/MM/YY', 'DD/MM/YYYY', 'DD-MMM-YYYY', 'DD MMM', 'hh:mm A', 'hh:mm'];
  let d = dayjs(text, formats, true);
  if (d.isValid()) {
    return d.format('DD/MM/YYYY');
  }
  return text;
}

class ConvertPage extends LitElement {
  createRenderRoot() {
    return this; // Disable Shadow DOM
  }

  static properties = {
    selectedBank: { type: String, state: true },
    progressMessage: { type: String, state: true },
    errorMessage: { type: String, state: true }
  };

  constructor() {
    super();
  }

  render() {
    return html`
      <bank-select @bank-selected=${this._handleBankSelected}></bank-select>
      ${this.selectedBank ?
        html`<file-input @file-confirmed=${this._handleFileConfirmed}></file-input>`
        : ''}
      <info-box type='error' message=${this.errorMessage}></info-box>
      <info-box type='normal' message=${this.progressMessage}></info-box>
    `;
  }

  _handleBankSelected(event) {
    this.selectedBank = event.detail.bank;
    console.debug('selectedBank', this.selectedBank);
  };

  _handleFileConfirmed(event) {
    const file = event.detail.file;
    console.debug('processing file', file);

    this.progressMessage = 'Start processing ${file.name}';

    const reader = new FileReader();
    reader.onload = () => {
      this._convertToCSV(reader.result, file.name, this.selectedBank)
        .then(() => {
          // Indicate success for this file
          console.log(`File ${file.name} processed successfully`);
          this.progressMessage = `File ${file.name} processed successfully`;
        })
        .catch(error => {
          // Handle errors gracefully
          console.error(`Error processing ${file.name}:`, error);
          this.errorMessage = `Error processing ${file.name}: ${error}`;
        });
    };

    reader.readAsArrayBuffer(file);

  };

  _rowsToCSV(data) {
    // 1. Gather all unique keys
    const allKeys = new Set();
    for (const row of data) {
      for (const key of Object.keys(row)) {
        allKeys.add(key);
      }
    }

    // 2. Create custom header
    const headerRow = {};
    for (const key of allKeys) {
      let display = key;
      if (key == 'minus') display = 'withdrawal';
      if (key == 'plus') display = 'deposit';
      
      headerRow[key] = display; 
    }
    const dataWithHeader = [headerRow, ...data];

    const csvData = Papa.unparse(dataWithHeader, {
      header: false,
      delimiter: ",",
      escapeChar: '"', // Specify the escape character
      quotes: true // Always quote fields 
    });

    return csvData;
  }

  _downloadCSV(csvContent, filename) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename || 'download.csv'; // Use a default filename, if needed
    link.click();
    URL.revokeObjectURL(url); // Clean up
  }

  async _convertToCSV(arrayBuffer, filename, selectedBank) {
    const loadingTask = getDocument(arrayBuffer);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    const batchSize = 3;
    try {
      const allData = {};
      const allRows = [];
      let indexOffset = 0;
      let previousLastRow;

      for (let pageNo = 1; pageNo <= numPages;) {

        const batches = [];
        for (let i = 0; i < batchSize && pageNo <= numPages; i++, pageNo++) {
          const page = await pdf.getPage(pageNo);
          const pageTextContent = await page.getTextContent();
          const tokens = [];
          let index = 0;

          for (const content of pageTextContent.items) {
            const [scaleX, bobo, dada, scaleY, tx, ty] = content.transform;

            if (content.str.trim() === "") continue;

            const indexInAll = index + indexOffset;
            index += 1;

            const token = generateToken(indexInAll, content)

            allData[indexInAll] = token.storedText;
            tokens.push(token.token);
          }

          batches.push({
            pageNo,
            tokens
          });
          indexOffset += tokens.length;
        }

        // Serialize and send to API using fetch (for example)
        const jsonData = JSON.stringify({ batches, previousLastRow });
        console.debug('data: ', jsonData);

        const apiResponse = await fetch(`convert/${selectedBank.toLowerCase()}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: jsonData
        });


        if (!apiResponse.ok) {
          throw new Error(`API request failed with status ${apiResponse.status}`);
        }

        const rows = await apiResponse.json(); // Assuming API returns an array of strings
        if (rows) {
          console.debug('rows ', rows);

          // Remove the last row from previous data.
          if (allRows.length > 0) {
            allRows.pop();
          }
          // Record the last row, this will pass into the next API call
          // When the next API return, this row will always be the head
          // Hence we remove the last row from previous data.
          previousLastRow = rows[rows.length - 1];

          allRows.push(...(rows.map(row => {
            const originalEntries = Object.fromEntries(
              Object.entries(row).map(([key, value]) => {
                if (Array.isArray(value)) {
                  // Process if value is an array
                  const processedArray = value.map(item => allData[item] || item).join('\n');
                  return [key, processedArray];
                } else {
                  // Original handling for non-array values
                  return [key, allData[value] || value];
                }
              })
            )

            originalEntries['date'] = convertDate(originalEntries['date']);

            const descriptions = originalEntries['descriptions'];
            const refs = originalEntries['refs'];
            const joinedString = descriptions + (refs ? `\n${refs}` : '');
            // console.debug('joinedString ', joinedString);

            const serialFromDescriptions = splitDescription(joinedString, 'description');
            // console.debug('serialFromDescriptions ', serialFromDescriptions);

            return { ...originalEntries, ...serialFromDescriptions };
          }
          )));
        }
      }
      const csv = this._rowsToCSV(allRows);
      // textContentDiv.textContent = csv;
      this._downloadCSV(csv, `${filename}.csv`);
    } catch (error) {
      console.error("Error processing text content:", error);
      throw error;
    }
  }
}

customElements.define('convert-page', ConvertPage);
